#Home {
    position: relative;
    display: flex;
    flex-direction: column;

    #info-section {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        padding: 3rem 7rem;
        margin-top: 6rem;
        margin-bottom: 4rem;
        background-color: rgba(180, 218, 253, 0.32);

        article.info-container {
            flex: 45% 0;
            color: $main-color;

            .title-container {
                font-size: 0.9rem;

                .top-line {
                    height: 0.2rem;
                    background-color: $secondary-color;
                    width: 10%;
                }

                .title {
                    padding-top: 1rem;
                    font-weight: 600;
                    padding-bottom: 1.5rem;
                }
            }

            .description {
                font-size: 0.9rem;
                line-height: 1.5rem;
            }
        }

        .image-container {
            flex: 40% 0;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 43rem;
            }
        }
    }

    .home-content {
        padding: 3.2vmax 5%;

        .books-list-container {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1rem;
            padding-inline: 2rem;
            column-gap: 4%;

            .list-title {
                flex: 100%;
                font-size: 1.1rem;
                display: flex;
                justify-content: center;
                color: $main-color;
                margin-bottom: 2rem;

                h2 {
                    font-weight: 550;
                }
            }

            .book-image {
                flex: 40% 0;
                display: flex;
                align-items: center;

                img {
                    max-width: 100%;
                }
            }

            .levels-container {
                flex: 55% 0;
                display: flex;
                flex-direction: column;
                margin-block: 3rem;
                gap: 4rem;

                .expansion-panel {
                    .line-title {
                        font-size: 1rem;
                        color: $main-color;

                        h3 {
                            padding-block: 1rem;
                        }
                    }

                    .grades-container {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-evenly;
                        align-items: flex-start;
                        padding-block: 2rem;
                        padding-inline: 2vmax;
                        padding-bottom: 0;
                        column-gap: 2vmax;
                        row-gap: 2rem;

                        .grade-item {
                            flex: 25% 0;
                            border: 0.1rem solid $main-color;
                            padding: 1rem;
                            border-radius: 0.7rem;
                            text-align: center;
                            color: $main-color;
                            cursor: pointer;
                            transition: 0.1s;

                            &:hover {
                                background-color: #ddeefe;
                            }
                        }
                    }
                }

                .not-found-container {
                    font-size: 1.3rem;
                    color: $secondary-color;
                    font-weight: 550;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    #Home {
        #info-section {
            .image-container {
                flex: 25% 0 !important;

                img {
                    left: 15%;
                    width: 230%;
                }
            }
        }

        .home-content {
            .books-list-container {
                padding-inline: 0 !important;

                .grades-container {
                    justify-content: space-evenly;
                    padding-inline: 0 !important;

                    .grade-item {
                        flex: 29% 0 !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #Home {
        #info-section {
            flex-direction: column;
            align-items: center;
            gap: 2rem;

            .image-container {
                width: 75%;

                img {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 100%;
                    transform: unset;
                }
            }
        }

        .home-content {
            .book-image {
                display: none !important;
            }

            .books-list-container {
                padding-inline: 5% !important;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    #Home {
        #info-section {
            padding-inline: 4rem;

            .image-container {
                width: 100%;
            }
        }

        .list-title {
            font-size: 1.2rem !important;
        }

        .home-content {
            .books-list-container {
                .grades-container {
                    justify-content: flex-start !important;

                    .grade-item {
                        flex: 45% 0 !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 380px) {
    #Home {
        .slideshow-actions {
            > * {
                width: 1.5rem !important;
                height: 1.5rem !important;

                svg {
                    width: 1rem !important;
                }
            }
        }

        #info-section {
            margin-top: 0 !important;
            padding-inline: 2rem;

            .title-container {
                .top-line {
                    width: 25% !important;
                }
            }
        }

        .list-title {
            font-size: 1.2rem !important;
        }

        .home-content {
            .books-list-container {
                .grades-container {
                    justify-content: flex-start !important;

                    .grade-item {
                        flex: 100% !important;
                    }
                }
            }
        }
    }
}

