.line-title {
    position: relative;
    text-align: center;
    font-size: 1.5rem;
    text-align: left;
    cursor: pointer;

    h3 {
        margin-bottom: 0.4rem;
    }

    .operations {
        position: absolute;
        right: 1%;
        top: 50%;
        transform: translateY(-50%);
    }

    .row {
        width: 100%;
        border-bottom: 0.3rem solid $secondary-color;
    }
}
