@import "../../assets/styles/utils.scss";

.mini-chat-space {
	z-index: 10;
	position: fixed;
	bottom: 1rem;
	right: 1rem;

	.mini-chat-container {
		display: flex;
		flex-direction: column;
		max-height: 93vh;
		height: 35rem;
		width: 25rem;
		max-width: 90vw;
		background-color: white;
		border: 0.2rem solid $dark-secondary-color;
		border-radius: 0.5rem;

		.mini-chat-header {
			z-index: 2;
			flex: 10%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-inline: 1rem;
			background-color: rgba(180, 218, 253, 0.32);
			box-shadow: 0px 4px 31px -17px rgba(0, 0, 0, 0.25);

			.title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 0.4vmax;
				font-size: 1rem;
				color: #003876;
			}

			.mini-chat-close {
				font-size: 1.8rem;
				line-height: 0;
				font-weight: bold;
				color: $dark-secondary-color;
				cursor: pointer;
			}
		}

		.mini-chat {
			flex: 90%;
			background-color: #fafafa;
			padding: 1.5rem 1rem;
			padding-bottom: 0;
			display: flex;
			flex-direction: column;
			gap: 1.3rem;
			overflow-y: auto;

			.loading-answer-container {
				display: flex;
				justify-content: center;
			}
		}

		.mini-chat-actions {
			display: flex;
			justify-content: flex-end;
			padding: .5rem 1rem;

			.clear-action {
				position: relative;
				background-color: #003876;
				padding: 1.5rem;
				border-radius: 50%;
				cursor: pointer;

				&:hover {
					opacity: 0.88;
				}

				svg {
					@include absolute-center;
				}
			}
		}
	}

	.mini-chat-button {
		border: 0.2rem solid $dark-secondary-color;
		background-color: white;
		padding: 0.2rem;
		border-radius: 50%;
		cursor: pointer;

		.icon {
			padding: 2rem;
			background-color: $dark-secondary-color;
			border-radius: 50%;

			svg {
				@include absolute-center;
			}
		}
	}
}
