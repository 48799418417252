.slideshow {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 6256 / 2490;
    background-color: white;
    overflow: hidden;

    .image-container {
        > * {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                min-height: 100%;
            }
        }

        .current-image {
            left: 0;
            
            &.move-left {
                transition: 0.4s;
                transform: translateX(-100%);
            }

            &.move-right {
                transition: 0.4s;
                transform: translateX(100%);
            }
        }

        .prev-image {
            left: -100%;

            &.move-right {
                transition: 0.4s;
                transform: translateX(100%);
            }
        }

        .next-image {
            right: -100%;

            &.move-left {
                transition: 0.4s;
                transform: translateX(-100%);
            }
        }
    }

    .slideshow-actions {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;

        > * {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 2.3rem;
            height: 2.3rem;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            cursor: pointer;
            transition: 0.2s;

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }

        .prev {
            left: 1rem;
        }

        .next {
            right: 1rem;
        }
    }
}
