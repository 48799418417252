#ContactsPage {
    padding-block: 7rem;
    padding-inline: 6rem;

    section.contacts-mail-section {
        margin-top: 3.5rem;
        padding: 4rem 10rem;
        border-radius: 0.5rem;
        background-color: white;
        box-shadow: 0px 4px 4px 0px #bfbfbf40;
        font-weight: 300;
        font-size: 0.85rem;

        .contacts-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4rem;

            .separation-column {
                background-color: red;
                width: 0.15rem;
                height: 4rem;
            }

            .contact-container {
                flex: 30%;
                padding-inline: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2rem;

                .info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    span {
                        display: block;
                    }
                }
            }
        }

        .mail-section {
            .title {
                color: $main-color;
                font-size: 1.1rem;
                font-weight: 550;
                margin-bottom: 1rem;
            }

            form.contact-form {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                margin-top: 2.5rem;

                .double-column {
                    display: flex;
                    gap: 1.8rem;
                }

                textarea,
                input {
                    width: 100%;
                    padding: 1.2rem 1rem;
                    border: 0.1rem solid #c8c8c8;
                    border-radius: 0.5rem;
                    font-size: 0.9rem;
                    font-weight: 300;
                    color: black;
                    resize: none;

                    &::placeholder {
                        color: black;
                        opacity: 1;
                    }

                    &:-ms-input-placeholder {
                        color: black;
                    }

                    &::-ms-input-placeholder {
                        color: black;
                    }
                }

                textarea {
                    height: 13rem;
                }

                .action-container {
                    display: flex;
                    justify-content: center;

                    button {
                        padding: 0.7rem 4rem;
                        border: none;
                        border-radius: 5rem;
                        background-color: $secondary-color;
                        color: white;
                        cursor: pointer;

                        &:hover {
                            background-color: $dark-secondary-color;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    #ContactsPage {
        section.contacts-mail-section {
            padding: 5rem !important;
        }
    }
}

@media screen and (max-width: 850px) {
    #ContactsPage {
        section.contacts-mail-section {
            .contacts-section {
                flex-direction: column;

                .separation-column {
                    height: 0.15rem;
                    width: 10rem;
                    margin-block: 2rem;
                }
            }

            form.contact-form {
                .double-column {
                    flex-direction: column;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    #ContactsPage {
        padding-inline: 5% !important;

        section.contacts-mail-section {
            padding: 7% !important;
        }
    }
}