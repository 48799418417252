.side-title {
    color: black;
    text-align: center;
    font-size: 1rem;
    text-align: left;

    h3 {
        margin-bottom: 0.2rem;
        font-weight: 500;
    }

    .row {
        width: 30%;
        border-bottom: 0.2rem solid $secondary-color;
    }
}
