#LibraryPage {
    .library-banner {
        position: relative;
        width: 100%;

        img {
            width: 100%;
        }

        .library-title {
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translateX(-50%);

            color: white;
            font-weight: 550;
            font-size: 5.3rem;
        }
    }

    .library-content {
        padding: 4rem 6rem;

        .filters-container {
            height: 0;
            overflow: visible;
            position: relative;
            z-index: 2;

            .filters-section {
                display: flex;
                justify-content: flex-end;
                gap: 2rem;

                > *:not(.divider) {
                    padding-block: 0.2rem;
                }

                .divider {
                    align-self: stretch;
                    width: 0.1rem;
                    background-color: #ddd;
                }

                .search-container {
                    min-width: 20rem;
                }

                .selector-container {
                    min-width: 15rem;
                }
            }
        }

        section.list-container {
            margin-top: 0.3rem;
            padding-bottom: 3.5rem;

            .red-bar-text-container {
                h5 {
                    font-weight: 500;
                }
            }

            .library-url {
                margin-top: 1rem;
                margin-left: 1.5rem;
                font-size: 0.9rem;
            }

            > .books-list {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                column-gap: 5%;
                row-gap: 3rem;

                width: 100%;
                padding-top: 3rem;
                padding-bottom: 1rem;

                > * {
                    flex: 30% 0;
                }
            }
        }

        .not-found {
            width: 100%;
            text-align: center;
            font-size: 1.5rem;
            margin-block: 3rem;
            font-weight: 550;

            svg {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #LibraryPage {
        .filters-container {
            height: unset !important;
            margin-bottom: 1.5rem;
        }

        .list-container {
            .books-list {
                > * {
                    flex: 47% 0 !important;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    #LibraryPage {
        .library-banner {
            .library-title {
                font-size: 4vmax;
            }
        }

        .list-container {
            .books-list {
                padding-inline: 7%;

                > * {
                    flex: 100% 0 !important;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #LibraryPage {
        .filters-container {
            .search-container {
                min-width: unset !important;
                width: 100% !important;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    #LibraryPage {
        .filters-container {
            margin-bottom: 3rem !important;

            .filters-section {
                flex-direction: column !important;
                gap: 0.8rem !important;

                .divider {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    #LibraryPage {
        .library-content {
            padding-inline: 10% !important;

            .list-container {
                .books-list {
                    padding-inline: 0 !important;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #LibraryPage {
        .library-content {
            padding-inline: 5% !important;

            .books-list {
                flex-direction: column;
                align-items: center;
                gap: 3rem !important;

                .book-card {
                    max-width: 100%;

                    .image-container {
                        flex: 30% 0 !important;
    
                        img {
                            width: 100% !important;
                        }
                    }

                    a {
                        flex-direction: column;
                        gap: 0 !important;

                        .info-container {
                            padding: 1rem !important;

                            .autor {
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
