#breadcrumbs {
	width: 100%;
	display: flex;
	align-items: center;
	column-gap: 1rem;
	margin-bottom: 4rem;
	font-size: 0.8rem;

	.breadcrumb {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: black;
	}

    .breadcrumb:first-of-type {
        svg {
            margin-right: 1rem;
        }
    }

	.breadcrumb:not(:first-of-type) {
		&::before {
			content: "/";
			color: black;
			font-weight: normal;
			margin-right: 0.5rem;
		}
	}

	.breadcrumb:not(:last-child) {
		cursor: pointer;

		&:hover {
			span {
				text-decoration: underline;
			}
		}
	}

	.breadcrumb.active {
		color: $main-color;
		font-weight: 450;
	}
}

@media screen and (max-width: 850px) {
	#breadcrumbs {
		> * {
			flex: 100%;
		}
	}
}
