.checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .checkbox-item {

        &.active {
            .check {
                display: block !important;
            }
        }

        input {
            display: none;
        }

        .label {
            @include select-none;
            display: flex;
            gap: 0.5rem;
            cursor: pointer;

            .fake-checkbox {
                width: 1.5rem;
                height: 1.5rem;
                border: 0.2rem solid $main-color;
                border-radius: .3rem;

                .check {
                    display: none;
                }
            }
        }
    }
}
