#chat-bot {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .chat-bot-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 1.75rem;

        .close-button {
            cursor: pointer;

            &:hover {
                filter: brightness(0.5);
            }
        }
    }

    .chat-bot-body {
        flex: 100%;
        max-height: 95%;
        border-left: 0.1rem dashed #d6d6d6;
        padding-left: 1.75rem;
    }
}