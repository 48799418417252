.red-bar-text-container {
	display: flex;
	align-items: center;
	position: relative;

	.red-bar {
		content: "";
		width: 8px;
		height: 35px;
		background-color: red;
		margin-inline-end: 15px;
	}

	.big-red-bar {
        height: 45px;
    }

	.red-bar-content {
		width: 100%;
		display: flex;
		align-items: center;

		.title-container {
			max-width: 50%;

			h5 {
				font-size: 1.2rem;
				font-weight: 500;
			}

			h6 {
				font-size: 0.9rem;
				font-weight: 400;
				color: #44444f;
			}
		}

		.link-container {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			text-decoration: none;
			color: black;
			display: flex;
			gap: 1rem;
			padding-right: 1vmax;
			transition: 0.2s;
			cursor: pointer;
			max-width: 45%;
			text-align: right;

			&:hover {
				text-decoration: underline;
				gap: 2rem;
				padding-right: 0;
			}
		}
	}
}
