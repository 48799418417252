#BookReader {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-block: 4rem;

	.page-top {
		padding-inline: 6rem;

		.red-bar-text-container {
			width: 100%;
		}
	}

	#book-reader-container {
		width: 100%;
		position: relative;
		display: flex;
		justify-self: flex-start;
		align-items: stretch;

		&.show-chat-bot {
			.book-container {
				width: 55%;
				padding-right: 1.75rem;
			}

			.chat-bot-container {
				width: 45%;
				max-width: 10000px;
				padding-block: 3rem;
				padding-right: 2rem;
			}
		}

		.book-container {
			width: 100%;
			padding-block: 3rem;
			padding-inline: 3.5rem;
			display: flex;
			flex-direction: column;
			gap: 2rem;

			.book-options {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				min-height: 3rem;
			}
		}

		.chat-bot-container {
			max-width: 0;
			overflow: hidden;
		}
	}
}

#over-chat-bot-container {
	display: none;
	z-index: 10;
}

@media screen and (max-width: 850px) {
	main.hide-all {
		display: none;
	}

	#BookReader {
		.page-top {
			padding-inline: 3rem;
		}

		#book-reader-container {
			.book-options {
				min-height: 0 !important;
			}

			.book-container {
				padding-inline: 1rem;
			}

			.chat-bot-container {
				display: none;
			}
		}
	}

	#over-chat-bot-container {
		display: block;
		position: fixed;
		background-color: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		padding: 1rem;

		#chat-bot {
			background-color: white;
			border: 0.2rem solid $dark-secondary-color;
			border-radius: 0.5rem;
			gap: 0 !important;

			.chat-bot-header {
				z-index: 2;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0.6rem 1rem;
				margin: 0;
				background-color: rgba(180, 218, 253, 0.32);
				box-shadow: 0px 4px 31px -17px rgba(0, 0, 0, 0.25);
			}

			.chat-bot-body {
				flex: 80%;
				height: 80%;
				padding-left: 0;
				border-left: none;

				.chat-container {
					.chat-body {
						height: 85%;
						flex: 85%;

						#chat-content {
							height: 100%;
							padding-top: 2rem;
							padding-left: 1rem;
						}
					}

					.chat-actions {
						max-width: 100%;
						max-height: 15% !important;
						padding-inline: 0.3rem;

						.question-input-container {
							box-sizing: border-box;
							max-width: 100%;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	#BookReader {
		#breadcrumbs {
			flex-wrap: wrap;
			row-gap: 1rem;
		}
	}
}
