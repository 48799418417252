#login-page,
#confirm-mail-page {
	width: 100%;
	min-height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem;

	background-size: cover;
	background-position: top center;

	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $main-color;
		opacity: 0.73;
		z-index: 0;
	}

	.login-container {
		background: #ffffff;
		height: auto;
		max-width: 100%;
		width: fit-content;
		border-radius: 30px;
		padding: 40px;
		margin-block: 2rem;
		z-index: 3;

		.logo-container {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			text-align: center;
			margin-block: 30px;
		}

		.login {
			text-align: center;
		}

		.form-container {
			width: 475px;
			text-align: center;
			margin-top: 10px;

			.submit-btn {
				border: none;
				background-color: $main-color;
				color: white;
				font-weight: 600;
				text-align: center;
				border-radius: 10px;
				margin-top: 15px;
				cursor: pointer;

				span {
					display: block;
					padding-inline: 80px;
					padding-block: 20px;
				}

				.loading-component {
					padding-inline: 75px;
					padding-block: 10px;
				}

				&:hover {
					filter: brightness(0.9);
				}
			}

			.input-container {
				input {
					width: 100%;
					height: 45px;
					margin-block: 10px;
					border-radius: 7px;
					border: 1px solid gray;
					padding-inline-start: 15px;
				}

				select {
					width: 100%;
					height: 45px;
					margin-block: 10px;
					border-radius: 7px;
					border: 1px solid gray;
					padding-inline-start: 5px;

					option[value="init"] {
						color: #a9a9a9;
					}
				}

				.input-field {
					input {
						margin-bottom: 0;
					}

					.errors-container {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						margin-bottom: 10px;

						.error {
							color: red;
							font-size: 0.8rem;
						}
					}
				}
			}

			.links-container {
				.reesend-code-link {
					font-size: 0.8rem;
					color: #0000ee;
					cursor: pointer;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.signed-in-modal,
		.confirm-message-container,
		.init-form-modal {
			width: 600px;
			max-width: 100%;

			.form-container {
				max-width: 90%;
			}

			.content {
				padding-inline: 1rem;
				padding-block: 1rem;

				h2 {
					white-space: nowrap;
					padding-block: 1rem;
					text-align: center;
				}
			}

			.action {
				padding-top: 1rem;
				display: flex;
				justify-content: center;

				.back-button {
					background-color: $main-color;
					border: 0.2rem solid $main-color;
					padding: 0.4rem 3.5rem;
					border-radius: 3rem;
					font-size: 0.9rem;
					font-weight: 550;
					color: white;
					cursor: pointer;
					transition: 0.2s;
					text-decoration: none;

					&:hover {
						background-color: white;
						color: $main-color;
					}
				}
			}

			.loading-container {
				display: flex;
				padding-top: 3rem;
				padding-bottom: 1rem;
				justify-content: center;
			}
		}

		.init-form-modal {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}

	.change-link-container,
	.resend-link-container,
	.restore-password-link-container {
		display: flex;
		justify-content: center;

		.change-link,
		.resend-link {
			color: white;
			text-decoration: underline;
			font-size: 1.2rem;
			padding-bottom: 1rem;
			cursor: pointer;
		}
	}

	.resend-link-container,
	.restore-password-link-container {
		.resend-link {
			font-size: 0.8rem;
		}
	}
}

@media screen and (max-width: 700px) {
	main#login-page,
	main#confirm-mail-page {
		.signed-in-modal,
		.login-container,
		.init-form-modal {
			width: 80vw !important;

			.content {
				text-align: center;
				padding-top: 0 !important;

				h2 {
					white-space: break-spaces !important;
					padding-top: 0 !important;
				}
			}
		}

		.init-form-modal {
			.form-container {
				width: 100% !important;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	main#login-page {
		.login-container {
			max-width: 90%;
			padding: 1.5rem;

			.form-container {
				width: auto;

				.input-double-column {
					flex-direction: column !important;
				}
			}
		}
	}
}

@media screen and (max-width: 450px) {
	main#login-page,
	main#confirm-mail-page {
		.logo-container {
			flex-direction: column;
			gap: 0.5rem !important;
			margin-bottom: 15px !important;

			.header-logo-icon {
				width: 9rem !important;
			}
		}
	}
}
