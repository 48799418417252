.chat-container {
    height: 100%;
    max-height: 98vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Segoe UI";
    font-style: normal;

    .chat-body {
        flex: 100%;

        .empty-chat-message {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .logo-image {
                height: 120px;
            }

            .message-title {
                font-weight: 700;
                font-size: 2vmax;
                display: flex;
                align-items: flex-end;
                text-align: center;
                line-height: 24px;
                margin-top: 36px;
                margin-bottom: 0px;
            }

            .message-subtitle {
                margin-top: 20px;
                font-weight: 400;
                font-size: 1vmax;
                line-height: 150%;
                display: flex;
                align-items: flex-end;
                text-align: center;
                letter-spacing: -0.01em;
                color: #616161;
            }
        }

        #chat-content {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            max-height: 88vh;
            overflow-y: auto;
            padding-right: 0.3rem;

            .user-question {
                max-width: 90%;
                align-self: flex-end;
                padding: 0.5rem 0.8rem;
                background-color: white;
                border: 0.1rem solid #ababab;
                border-radius: 0.35rem;
                white-space: pre-wrap;
                word-wrap: break-word;
            }

            .answer-box {
                max-width: 90%;
                align-self: flex-start;
                white-space: pre-wrap;
            }

            .loading-answer {
                align-self: center;
                padding: 0.5rem 1rem;
            }

            #end-of-chat {
                visibility: hidden;
            }
        }
    }

    .chat-actions {
        flex-grow: 2;
        position: relative;
        display: flex;
        align-items: center;
        gap: 1rem;

        .stop-button {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -120%);
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem;
            border: 0.1rem solid red;
            background-color: white;
            border-radius: 0.35rem;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
                text-decoration-color: red;
            }
        }

        .question-input-container {
            flex: 100%;
        }
    }
}
