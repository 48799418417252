#SubjectDetail {
    padding: 2.5rem 7rem;

    section.subject-details-card-container {
        margin-bottom: 5rem;

        article#subject-details-card {
            position: relative;
            padding: 2rem 4rem;
            background-color: white;
            box-shadow: 0px 4px 4px 0px #bfbfbf40;
            border-radius: 0.35vmax;

            display: flex;
            gap: 6%;
            align-items: center;

            .image-container {
                flex: 40%;
                display: flex;
                justify-content: center;

                img {
                    max-width: 100%;
                }
            }

            .details-container {
                flex: 60%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                color: #594d50;

                .title {
                    color: $main-color;
                    font-weight: 600;
                    font-size: 1.2rem;
                }

                .publisher {
                    font-size: 1.2rem;
                    font-weight: 400;
                }

                .description {
                    font-size: 0.9rem;
                }

                .grade {
                    color: $secondary-color;
                }
            }
        }
    }

    .filters-container {
        height: 0;
        overflow: visible;
        position: relative;
        z-index: 2;

        .filters-section {
            display: flex;
            justify-content: flex-end;
            gap: 2rem;

            > *:not(.divider) {
                padding-block: 0.2rem;
            }

            .divider {
                align-self: stretch;
                width: 0.1rem;
                background-color: #ddd;
            }

            .search-container {
                width: 20rem;
            }
        }
    }

    section.subject-books {
        margin-top: 0.3rem;
        padding-bottom: 3.5rem;

        .card-list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            column-gap: 5%;
            row-gap: 3rem;
            width: 100%;
            padding-top: 3rem;
            padding-bottom: 1rem;

            > *:not(.not-found)  {
                flex: 30% 0;
                flex-shrink: 0;
            }
        }
    }

    .red-bar-text-container {
        h5 {
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 1200px) {
    #SubjectDetail {
        .list-container {
            .card-list {
                > *:not(.not-found) {
                    flex: 47% 0 !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #SubjectDetail {
        #subject-details-card {
            flex-direction: column;
            gap: 2rem !important;
        }

        .filters-container {
            height: unset !important;
            margin-bottom: 2rem;
        }
    }
}

@media screen and (max-width: 900px) {
    #SubjectDetail {
        padding-inline: 5%;

        #subject-details-card {
            margin-inline: 10%;
        }

        .list-container {
            .card-list {
                padding-inline: 7%;
                
                > *:not(.not-found)  {
                    flex: 100% 0 !important;
                }
            }
        }
    }
}

@media screen and (max-width: 680px) {
    #SubjectDetail {
        padding-inline: 10%;

        #subject-details-card {
            margin-inline: 0;
            padding-inline: 10% !important;
        }

        .filters-container {
            margin-bottom: 3rem !important;
            
            .filters-section {
                flex-direction: column !important;
                gap: 0.8rem !important;

                .search-container {
                    width: 100%;
                }

                .divider {
                    display: none;
                }
            }
        }

        .list-container {
            .card-list {
                padding-inline: 0;

                > *:not(.not-found)  {
                    flex: 100% 0 !important;
                }
            }
        }

        .card-list {
            flex-direction: column;
            align-items: center;
            gap: 3rem !important;

            .book-card,
            .media-file-card {
                flex: 45% 0 !important;
                max-width: 100%;

                .image-container {
                    flex: 30% 0 !important;

                    img {
                        width: 100% !important;
                    }
                }

                a,
                .media-file-card-content {
                    flex-direction: column;
                    gap: 0 !important;

                    .info-container {
                        padding: 1rem !important;

                        .autor {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }
}