.question-input-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: white;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0.35rem;
    padding-inline: 1rem;

    #question-input {
        flex: 100%;
        border: none;
        outline: none;
        resize: none;
        padding-top: 1rem;
        min-height: 50px;
        max-height: 110px;
    }

    .send-button {
        cursor: pointer;
    }
}
