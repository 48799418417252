.App {
    header#app-header {
        width: 100%;
        position: relative;
        z-index: 2;
        box-shadow: 0px 4px 31px -17px rgba(0, 0, 0, 0.25);

        .header-content {
            min-height: 7rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: white;
            padding: 1rem 3rem;

            .header-left {
                display: flex;
                align-items: center;
                gap: 3rem;
                margin-left: 3rem;

                .logo-container {
                    text-decoration: none;

                    img {
                        margin-bottom: -0.2rem;
                    }

                    .horizontal-row {
                        width: 25%;
                        height: 0.1rem;
                        background-color: $secondary-color;
                        margin-bottom: 0.2rem;
                        margin-left: 0.15rem;
                    }

                    .header-title {
                        font-size: 0.7rem;
                        font-weight: normal;
                        color: $secondary-color;
                        margin-left: 0.15rem;
                    }
                }

                .header-navbar {
                    .links {
                        list-style: none;
                        display: flex;
                        align-items: center;
                        gap: 2.5rem;
                        font-size: 0.9rem;

                        .link {
                            &.active {
                                a {
                                    color: $secondary-color;
                                }
                            }

                            &:hover {
                                border-bottom: 0.2rem solid $main-color;

                                &.active {
                                    border-bottom-color: $secondary-color;
                                }
                            }

                            a {
                                text-decoration: none;
                                color: $main-color;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .actions-container {
                flex: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 2rem;

                .overlay-search-icon {
                    display: none;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    height: 3rem;
                    width: 3rem;
                    border: 1px solid $main-color;
                    border-radius: 50%;
                    transition: 0.2s;

                    &.show {
                        background-color: $main-color;

                        svg {
                            @include svg-white-color;
                        }

                        &:hover {
                            background-color: white;

                            svg {
                                @include svg-main-color;
                            }
                        }
                    }

                    &:not(.show) {
                        svg {
                            @include svg-main-color;
                        }

                        &:hover {
                            background-color: $main-color;

                            svg {
                                @include svg-white-color;
                            }
                        }
                    }
                }

                #general-search {
                    width: 300px;

                    .search-field {
                        width: 20rem;
                    }
                }

                .user-actions {
                    position: relative;

                    &:hover {
                        section#perfil-card {
                            display: block;
                        }
                    }

                    .user-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        height: 3rem;
                        width: 3rem;
                        border: 1px solid #ddd;
                        border-radius: 50%;
                        transition: 0.2s;

                        &:hover {
                            background-color: #ddd;
                        }
                    }

                    section#perfil-card {
                        display: none;
                        z-index: 3;
                    }
                }
            }
        }

        .overlay-search-container {
            display: none;
            max-height: 0;
            overflow: hidden;
            width: 100%;
            z-index: 3;
            transition: 0.2s;
            box-shadow:
                rgba(0, 0, 0, 0.16) 0px 3px 6px,
                rgba(0, 0, 0, 0.23) 0px 3px 6px;

            &.show {
                max-height: 100000rem;
            }

            #general-search {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 1rem;
                background-color: white;
                border-top: 0.2rem solid $light-gray;
                box-shadow:
                    rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
                    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

                .options-container {
                    position: relative;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 15px;
                }
            }
        }

        #general-search {
            position: relative;
            display: flex;
            justify-content: flex-end;

            .search-field {
                z-index: 10;
                width: 100%;
            }

            .options-container {
                min-width: 100%;
                z-index: 5;
                position: absolute;
                top: 100%;
                right: 0;
                display: flex;
                flex-direction: column;
                max-height: 15rem;
                max-width: 40rem;
                overflow-y: auto;
                overflow-x: hidden;
                background-color: white;
                border-radius: 0.35rem;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                .book-item {
                    min-width: 100%;
                    color: $gray;
                    padding: 0.3rem 1rem;
                    cursor: pointer;
                    text-decoration: none;
                    white-space: nowrap;

                    &:hover {
                        color: black;
                        background-color: $light-gray;
                    }
                }

                .no-item {
                    min-width: 100%;
                    color: $gray;
                    padding: 0.3rem 1rem;
                    cursor: pointer;
                    text-decoration: none;
                }

                .loading-container {
                    padding: 1rem;
                }
            }
        }
    }

    main {
        position: relative;
        width: 100%;
        min-height: 88%;
        background-color: #fafafa;
        overflow-y: visible;
        overflow-x: hidden;

        > div {
            position: relative;
            width: 100%;
            min-height: 100%;
            z-index: 1;
            overflow: hidden;
        }

        .ministerio-icon {
            z-index: 0;
            position: absolute;
            bottom: 3%;
            right: -10%;
        }

        &.left {
            .ministerio-icon {
                left: -10%;
            }
        }
    }

    footer#app-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        background-color: $main-color;
        width: 100%;

        .minerd-info {
            padding-block: 3rem;

            .minerd-images {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 2rem;

                a img {
                    filter: brightness(150%);
                }
            }

            .minerd-data {
                display: flex;
                flex-direction: column;
                list-style: none;
                text-align: center;
                font-size: 0.75rem;
                color: #d1d9e2;
                gap: 0.2rem;

                a {
                    color: white;
                    font-weight: 550;
                }
            }
        }
    }
}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 10%;
    padding-bottom: 10%;
    justify-content: center;

    .loading-component {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.not-found-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1100px) {
    header#app-header {
        .header-content {
            padding-inline: 1.5rem !important;

            .actions-container {
                #general-search {
                    max-width: 70% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    header#app-header {
        .header-content {
            .actions-container {
                .overlay-search-icon {
                    display: flex !important;
                }

                .close-overlay {
                    display: none !important;
                }

                form#general-search {
                    display: none !important;
                }
            }
        }

        .overlay-search-container {
            display: block !important;
        }
    }
}

@media screen and (max-width: 760px) {
    header#app-header {
        .header-content {
            align-items: unset !important;

            .header-left {
                margin-left: 0 !important;
                flex-direction: column;
                align-items: flex-start !important;
                gap: 0.3rem !important;

                .header-navbar {
                    margin-left: 0.7rem;
                }
            }

            .actions-container {
                gap: 1rem !important;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    header#app-header {
        .header-content {
            padding-inline: 1rem !important;

            .header-left {
                .logo-container {
                    max-width: 50%;

                    .header-logo-icon {
                        width: 100% !important;
                    }
                }
            }

            .actions-container {
                position: absolute;
                top: 50%;
                right: 1rem;
                transform: translateY(-50%);

                .overlay-search-icon,
                .user-actions .user-icon {
                    height: 2rem !important;
                    width: 2rem !important;

                    > * {
                        width: 1rem !important;
                    }
                }

                #perfil-card {
                    font-size: 0.8rem;
                    max-width: 80vw !important;
                }
            }
        }
    }
}

@media screen and (max-width: 300px) {
    header#app-header {
        .header-navbar {
            .links {
                font-size: 0.7rem !important;
                gap: 1.5rem !important;
            }
        }
    }
}
