.clear-button {
    position: relative;
    padding: 1.3rem;
    background: radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0f6cbd 33.63%, #2d87c3 70.31%, #8dddd8 100%);
    border-radius: 50%;
    cursor: pointer;

    &:not(.disabled):hover {
        filter: brightness(0.9);
    }

    &.disabled {
        background: #bdbdbd;
    }

    .small-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
