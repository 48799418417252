#NotFoundAuthorized {
    display: flex;
    height: 80vh;
    justify-content: center;
    align-items: center;

    .not-found {
        display: flex;
        flex-direction: column;
        align-items: center;

        .not-found {
            width: 100%;
            text-align: center;
            font-size: 1.5rem;
            margin-bottom: 3rem;
            font-weight: 550;

            svg {
                max-width: 100%;
            }
        }

        .home-link {
            font-size: 1.3rem;
            color: $main-color;
        }
    }
}

#not-found-unauthorize-page {
    width: 100%;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-size: cover;
	background-position: top center;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $main-color;
        opacity: 0.73;
        z-index: 0;
    }

    .login-container {
        background: #ffffff;
        height: auto;
        max-width: 100%;
        width: fit-content;
        border-radius: 30px;
        padding: 40px;
        margin-block: 2rem;
        z-index: 3;

        .logo-container {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			text-align: center;
			margin-block: 30px;
		}

        .login {
            text-align: center;
        }

        .message-container {
            width: 600px;
            max-width: 100%;

            .content {
                padding-inline: 1rem;
                padding-block: 1rem;

                h2 {
                    white-space: nowrap;
                    padding-block: 1rem;
                    text-align: center;
                }
            }

            .action {
                padding-top: 1rem;
                display: flex;
                justify-content: center;

                .back-button {
                    background-color: $main-color;
                    border: 0.2rem solid $main-color;
                    padding: 0.4rem 3.5rem;
                    border-radius: 3rem;
                    font-size: 0.9rem;
                    font-weight: 550;
                    color: white;
                    cursor: pointer;
                    transition: 0.2s;
                    text-decoration: none;

                    &:hover {
                        background-color: white;
                        color: $main-color;
                    }
                }
            }
        }
    }

    .change-link-container {
        display: flex;
        justify-content: center;

        .change-link {
            color: white;
            text-decoration: underline;
            font-size: 1.2rem;
            cursor: pointer;
        }
    }
}
@media screen and (max-width: 700px) {
	main#not-found-unauthorize-page {
		.signed-in-modal,
		.login-container {
			width: 80vw !important;

			.content {
				text-align: center;
				padding-top: 0 !important;

				h2 {
					white-space: break-spaces !important;
					padding-top: 0 !important;
				}
			}
		}
	}
}

@media screen and (max-width: 550px) {
	main#not-found-unauthorize-page {
		.login-container {
			max-width: 90%;
			padding: 1.5rem;

			.form-container {
				width: auto;

				.input-double-column {
					flex-direction: column !important;
				}
			}
		}
	}
}

@media screen and (max-width: 450px) {
	main#not-found-unauthorize-page {
		.logo-container {
			flex-direction: column;
			gap: .5rem !important;
			margin-bottom: 15px !important;

			.header-logo-icon {
				width: 9rem !important;
			}
		}
	}
}

