#BookList {
    min-height: 88vh;
    display: flex;
    gap: 5vmax;

    .book-list-page {
        flex: 60%;
        display: flex;
        flex-direction: column;
        padding: 3rem 6.5rem;

        .books-list-container {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow-x: visible;

            .books-list {
                width: 100%;

                .loading-container {
                    min-height: 60vh;
                }

                .book-list-filters {
                    display: flex;
                    justify-content: end;
                    margin-top: 2rem;
                    column-gap: 1rem;
                }

                .book-section {
                    margin-bottom: 4rem;

                    .red-bar-text-container {
                        h5 {
                            font-size: 1.2rem;
                            font-weight: 550;
                        }
                    }

                    .books-container {
                        margin-top: 2rem;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 3rem;

                        > * {
                            flex: 45% 0;
                        }

                        .media-file-card {
                            max-height: 14rem;
                        }
                    }
                }

                > * {
                    flex: 40% 0;
                }
            }

            .not-found {
                width: 100%;
                text-align: center;
                font-size: 1.5rem;
                margin-bottom: 3rem;
                font-weight: 550;

                svg {
                    max-width: 100%;
                }
            }

            .no-grade {
                display: flex;
                flex-direction: column;
                align-items: center;

                .home-link {
                    font-size: 1.3rem;
                    color: $main-color;
                }
            }
        }
    }

    .filter-icon {
        position: absolute;
        top: 3rem;
        right: 6.5rem;
        cursor: pointer;
        transition: 0.1s;
        display: none;

        &:hover {
            opacity: 0.8;
        }
    }

    .filter-section {
        position: relative;
        flex: 20%;
        background-color: white;
        box-shadow: 0px 4px 17px 0px #00000012;
        padding: 5rem 3rem;

        .cancel-icon {
            position: absolute;
            top: 2rem;
            right: 2rem;
            cursor: pointer;
            display: none;

            &:hover {
                filter: brightness(0.7);
            }
        }

        .side-title {
            margin-bottom: 2rem;
        }

        form#filter-form {
            .search-field {
                margin-bottom: 3rem;
            }

            .checkbox-list {
                gap: 2rem;
                font-size: 0.9rem;
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    #BookList {
        gap: 0;

        .book-list-page {
            .book-card {
                .info-container {
                    .title {
                        font-size: 1rem;
                    }
                }
            }
        }

        .filter-icon {
            display: block;
        }

        aside.filter-section {
            width: 25rem;
            position: absolute;
            right: 0;
            height: 100%;
            max-width: 0;
            overflow: hidden;
            padding: 0;
            z-index: 1;

            &.show {
                max-width: 1000000px;
                padding: 5rem 3rem;
            }

            .cancel-icon {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 1150px) {
    .book-list-page {
        flex-direction: column;
        align-items: flex-end !important;

        .list-title {
            margin-right: 1rem;
        }

        .books-list-container {
            width: 100%;

            .book-card {
                .info-container {
                    .title {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .book-list-page {
        .books-container {
            flex-direction: column;
            align-items: center;
            gap: 3rem !important;

            .book-card {
                max-width: 85%;

                a {
                    .image-container {
                        flex: 30% 0;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .info-container {
                        .title {
                            font-size: 1rem;
                        }

                        .autor {
                            font-size: 0.8rem;
                        }

                        .editorial-container {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .book-list-page {
        .books-container {
            .book-card {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .book-list-page {
        padding-inline: 5% !important;
    }

    .filter-icon {
        right: 5% !important;
    }
}

@media screen and (max-width: 500px) {
    .book-list-page {
        padding-inline: 1rem !important;

        .red-bar-text-container {
            align-items: flex-start;

            .red-bar-content {
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column !important;

                h5 {
                    max-width: 100% !important;
                }

                .link-container {
                    align-self: flex-end;
                    margin-top: 1rem;
                    max-width: 100% !important;
                    position: relative !important;
                    top: unset !important;
                    right: unset !important;
                    transform: unset !important;

                    font-size: 0.8rem;
                }
            }
        }

        .books-container {
            flex-direction: column;
            align-items: center;
            gap: 3rem !important;

            .book-card {
                max-width: 85%;

                a {
                    flex-direction: column;
                    gap: 0 !important;

                    .info-container {
                        padding: 1rem !important;

                        .autor {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }

    .books-list-container {
        margin-top: 1rem;
    }

    .filter-icon {
        top: 7rem !important;
    }
}

@media screen and (max-width: 400px) {
    #BookList {
        .filter-section {
            &.show {
                width: 100% !important;
                padding-inline: 2rem !important;
            }
        }
    }
}
