.expansion-panel {
    .panel-content {
        max-height: 0;
        transition-duration: 0.3s;
        overflow-y: hidden;

        &.show {
            max-height: 1000px;
        }
    }
}
