.media-player-modal-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;

    .modal-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .media-player-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;

        padding: 2rem;
        padding-top: 2.3rem;
        background-color: white;
        border-radius: 1rem;

        &.audio {
        }

        &.video {
            height: 80vh;
        }

        .media-container {
            width: 100%;
            height: 100%;

            video {
                max-width: 100%;
                max-height: 100%;
            }

            audio {
                width: 99%;
            }
        }

        .info-container {
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: 1px solid #c1c1c1;

            .title {
                font-size: 1.1rem;
                font-weight: 550;
                color: $main-color;
            }

            .autor {
                font-weight: 300;
                font-size: 0.9rem;
            }
        }

        .cross-icon {
            position: absolute;
            right: 0.2rem;
            top: 0.2rem;
            cursor: pointer;

            &:hover {
                filter: brightness(0.5);
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .media-player-modal-container {
        .media-player-modal {
            width: 90%;
        }
    }
}
