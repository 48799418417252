.media-group {
    section.list-container {
        margin-top: 0.3rem;
        padding-bottom: 3.5rem;

        .card-list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            column-gap: 5%;
            row-gap: 3rem;
            width: 100%;
            padding-top: 3rem;
            padding-bottom: 1rem;

            > *:not(.not-found) {
                flex: 30% 0;
                flex-shrink: 0;
            }

            .not-found {
                flex: 100% !important;
                text-align: center;
            }
        }
    }
}