.pdf-reader {
    width: 100%;

    border: 0.01rem solid black;
    background-color: #eee;

    .reader-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        border-bottom: 0.01rem solid rgba(0, 0, 0, 0.1);

        .pagination-control {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: #494949;

            .page-input-container {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                #actual-page-number {
                    width: 3rem;
                    font-size: 0.9rem;
                    text-align: center;
                    padding: 0.3rem;
                    border: 1px solid #b4b4b4;
                    border-radius: 0.35rem;
                    outline: none;

                    -moz-appearance: textfield;
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }

                .slash {
                    font-size: 1.5rem;
                }
            }
        }

        .page-size-control {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        [class*="-button"] {
            display: flex;
            align-items: center;
            padding: 0.3rem;
            background-color: #eee;
            border-radius: 0.35rem;

            &.disable {
                svg {
                    fill: lightgray;
                }
            }

            &:not(.disable) {
                cursor: pointer;

                &:hover {
                    filter: brightness(0.9);
                }
            }
        }
    }

    .reader-body {
        width: 100%;
        display: flex;

        .reader-sidebar {
            height: 100%;
            padding-right: 1.5rem;
            border-right: 0.01rem solid rgba(0, 0, 0, 0.1);
        }

        .pdf-content {
            width: 100%;
            max-height: 90vh;
            border: 0.01rem solid rgba(0, 0, 0, 0.1);
            background-color: white;
            overflow: auto;
        }
    }
}

.page-document {
    position: relative;
    margin-block: 1rem;

    .page-item {
        width: 95%;
        margin-inline: auto;

        &.z-50 {
            width: calc(95% * 0.5);
        }
        &.z-75 {
            width: calc(95% * 0.75);
        }
        &.z-125 {
            width: calc(95% * 1.25);
        }
        &.z-150 {
            width: calc(95% * 1.5);
        }
    }

    
.loading-container {
    display: flex;
    justify-content: center;
    background-color: white;
    min-height: 22vh;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
    margin-inline: auto;
    margin-block: 1rem;

    .loading-component {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

}
