.search-field {
    position: relative;
    display: flex;
    border: 0.1rem solid #d3d3d3;
    border-right: none;
    border-radius: 2rem;
    background-color: white;

    &.principal {
        .search-button {
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
        }
    }

    > * {
        padding: 0.4rem 1rem;
    }

    .input-container {
        flex: 100%;

        input {
            width: 100%;
            border: none;
            outline: none;
            background-color: transparent;
        }
    }

    .search-button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 105%;
        border: 0.1rem solid $secondary-color;
        background-color: $secondary-color;
        border-radius: 2rem;
        cursor: pointer;

        &:hover {
            transition: 0.2s;
            filter: brightness(0.9);
        }
    }
}
