.media-file-card {
    height: 14rem;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #bfbfbf40;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;

    .media-file-card-content {
        height: 100%;
        display: flex;
        color: black;
        background-color: white;
        text-decoration: none;
        gap: 1rem;
    }

    &:hover {
        transform: scale(1.05);
    }

    .image-container {
        flex: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;

        img {
            width: 100%;
            width: auto;
        }
    }

    .info-container {
        flex: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;
        padding-left: 0;

        .info-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 0.8rem;
                color: $main-color;
                font-weight: 550;
                white-space: break-spaces;
            }

            .media-type-icon {
                margin-left: .5rem;
            }
        }

        .autor {
            font-size: 0.7rem;
            text-align: end;
        }
    }
}


@media screen and (max-width: 800px) and (min-width: 680px) {
    .media-file-card {
        .info-container {
            .info-header {
                flex-direction: column-reverse;

                > * {
                    flex: 100%;
                }

                .media-type-icon {
                    align-self: flex-end;
                    margin: 0;
                }
            }
        }
    }
}