#PerfilPage {
	padding-block: 7rem;
	padding-inline: 6rem;

	.perfil-page-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1.5rem;

		.red-bar-text-container {
			flex: 100%;
		}

		.perfil-actions {
			display: flex;
			gap: 1rem;

			button {
				border: 0.13rem solid;
				padding-inline: 1rem;
				padding-block: 0.7rem;
				border-radius: 0.6rem;
				cursor: pointer;
				font-weight: 550;
				transition: 0.2s;
			}

			.edit-button {
				background-color: transparent;
				border-color: #003876;
				color: #003876;

				&:hover {
					background-color: #003876;
					border-color: #003876;
					color: white;
				}
			}

			.cancel-button {
				background-color: transparent;
				border-color: #b3b3b3;
				color: #737373;

				&:hover {
					background-color: #b3b3b3;
					border-color: #b3b3b3;
					color: white;
				}
			}

			.save-button {
				background-color: #003876;
				border-color: #003876;
				color: white;

				&:hover {
					background-color: transparent;
					border-color: #003876;
					color: #003876;
				}
			}
		}
	}

	#perfil-data-form {
		width: 100%;
		display: flex;
		flex-direction: column;
		border-top: 0.1rem solid rgba(151, 151, 151, 0.3);

		.perfil-data-section {
			padding-block: 1rem;
			padding-right: 2rem;
			border-bottom: 0.1rem solid rgba(151, 151, 151, 0.3);

			display: flex;
			gap: 1rem;
			align-items: flex-start;

			.label-container {
				flex: 20%;
			}

			.input-container {
				flex: 80%;
				display: flex;
				align-items: center;
				gap: 1rem;

				.form-input {
					flex: 48% 0;
					padding: 0.7rem;
					background-color: white;
					border: 0.1rem solid #d8d8d8;
					border-radius: 0.6rem;
					outline: none;

					&:disabled {
						opacity: 10;
						background-color: #f6f6f6;
					}
				}

				.password-link {
					display: flex;
					align-items: flex-end;
					cursor: pointer;

					&:hover {
						opacity: 0.5;
					}
				}
			}
		}
	}

	.loading-container {
		margin-top: 7rem;
	}
}

@media screen and (max-width: 850px) {
	#PerfilPage {
		padding-block: 5rem;
		padding-inline: 2rem;

		.perfil-page-header {
			.perfil-actions {
				flex-direction: column;
			}
		}

		#perfil-data-form {
			.perfil-data-section {
				flex-direction: column;

				.input-container {
					width: 100%;
					flex-direction: column;

					.form-input {
						width: 100%;
					}
				}
			}
		}
	}
}
