.answer-container {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.answer-box {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		padding: 0.5rem 0.8rem;
		background-color: white;
		border: 0.1rem solid #003876;
		border-radius: 0.35rem;

		.answer-header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.copy-button {
				cursor: pointer;
				background-color: white;
				padding: 0.4rem;
				border-radius: 0.35rem;

				&:hover {
					filter: brightness(0.9);
				}
			}
		}

		.answer-body {
			margin-right: 1rem;
			word-wrap: break-word;
		}

		.answer-actions {
			display: flex;
			flex-direction: column;
			margin-top: 0.5rem;
			gap: 1rem;

			.answer-options {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				column-gap: 0.5rem;
				row-gap: 0.2rem;

				.options-title {
					font-weight: bold;
				}

				.option {
					padding: 0.3rem 0.5rem;
					font-size: 0.8rem;
					color: #003876;
					background-color: rgba(0, 56, 118, 0.2);
					border-radius: 0.35rem;
					cursor: pointer;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.answer-response {
		max-width: 90%;
		align-self: flex-end;
		padding: 0.5rem 0.8rem;
		background-color: white;
		border: 0.1rem solid #ababab;
		border-radius: 0.35rem;
		white-space: pre-wrap;
		word-wrap: break-word;
	}
}
