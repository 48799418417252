#BookDetail {
	padding: 4rem 6rem;

	section.book-details-card-container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		article#book-details-card {
			position: relative;
			flex: 100%;
			display: flex;
			gap: 4.5%;
			padding: 3rem;
			background-color: white;
			box-shadow: 0px 4px 15px -7px rgba(0, 0, 0, 0.25);
			border-radius: 0.5rem;
			overflow: hidden;

			.image-container {
				align-self: center;
				flex: 35% 0;

				img {
					width: 100%;
					box-shadow: 0px 4px 15px -7px rgba(0, 0, 0, 0.25);
				}

				.dual-image-container {
					display: flex;
					justify-content: center;
					gap: 0.4rem;
					margin-top: 0.5rem;
					margin-right: 1rem;

					> * {
						width: 0.8rem;
						height: 0.8rem;
						border-radius: 50%;
						background-color: #d9d9d9;
						cursor: pointer;
					}

					.active {
						background-color: $main-color;
					}
				}
			}

			.book-data-container {
				flex: 65% 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.details-container {
					display: flex;
					flex-direction: column;
					padding-top: 2rem;
					row-gap: 0.5rem;
					font-size: 0.9rem;

					.title {
						color: $main-color;
						font-size: 1.2rem;
						font-weight: 550;
					}

					.autor {
						color: $secondary-color;
						font-size: 0.9rem;
					}

					.publisher {
						font-size: 0.9rem;
					}

					.description {
						margin-top: 2rem;
						font-weight: 300;
					}

					.grade {
						color: $secondary-color;
						font-weight: 550;
					}
				}
			}

			.book-actions {
				display: flex;
				justify-content: flex-start;
				margin-top: 2rem;
				gap: 1.3rem;

				> *:not(.qrcode-button) {
					@include select-none;

					display: flex;
					align-items: center;
					justify-content: space-around;
					gap: 1.5rem;

					background-color: none;
					border: none;
					padding: 0.4rem 3.5rem;
					border-radius: 3rem;
					font-size: 0.9rem;
					color: white;
					cursor: pointer;
					transition: 0.2s;
				}

				.lector-btn {
					border: solid 0.2rem $main-color;
					background-color: $main-color;
					text-decoration: none;

					.medium-less-icon {
						@include svg-white-color;
					}

					&:hover {
						background-color: transparent;
						color: $main-color;

						.medium-less-icon {
							@include svg-main-color;
						}
					}
				}

				.download-btn {
					border: solid 0.2rem $secondary-color;
					background-color: $secondary-color;

					.small-icon {
						@include svg-white-color;
					}

					&:hover {
						background-color: transparent;
						color: $secondary-color;

						.small-icon {
							@include svg-secondary-color;
						}
					}
				}

				.qrcode-button {
					position: absolute;
					top: 1.5rem;
					right: 1.5rem;
					cursor: pointer;
				}
			}
		}

		.book-chapters-container {
			padding-top: 3rem;
			width: 100%;

			.red-bar-text-container {
				h5 {
					font-size: 1.1rem;
					font-weight: 500;
				}
			}

			.chapters-table {
				display: flex;
				flex-direction: column;
				gap: 1.3rem;
				padding-top: 2rem;

				.chapter-container {
					.chapter-item {
						display: flex;
						justify-content: space-between;
						padding: 1rem 3rem;
						border-radius: 0.35rem;
						box-shadow: 0px 4px 4px 0px #bfbfbf40;
						background-color: white;
						font-size: 0.9rem;
						font-weight: 550;
						color: $main-color;

						.chapters-actions {
							display: flex;
							margin-right: 2.9rem;
							gap: 1.5rem;

							> * {
								cursor: pointer;

								&:hover {
									filter: brightness(0.5);
								}
							}

							.download-icon {
								width: 1.2rem;
							}
						}
					}

					.media-state-button-container {
						position: relative;
						width: 100%;

						.media-state-button {
							position: absolute;
							right: 3rem;
							top: -1rem;
							transform: translateY(-75%);
							z-index: 10;
							cursor: pointer;

							&:hover {
								filter: brightness(0.5);
							}
						}
					}

					.chapter-media {
						padding-block: 2rem;

						.media-group {
							.list-container {
								padding-bottom: 1.5rem;

								.card-list {
									padding-top: 1rem;
								}

								.red-bar-text-container {
									padding-left: 3rem;

									.red-bar {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}

		.book-related-container {
			padding-top: 3rem;
			width: 100%;

			.red-bar-text-container {
				h5 {
					font-size: 1.1rem;
					font-weight: 500;
				}
			}

			.books-list {
				display: flex;
				justify-content: flex-start;
				gap: 5%;
				width: 100%;
				padding-top: 3rem;
				padding-bottom: 1rem;

				> * {
					flex: 30% 0;
					flex-shrink: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	#BookDetail {
		#book-details-card {
			.image-container {
				flex: 50% 0 !important;
			}
			.book-data-container {
				flex: 50% 0 !important;
			}

			.book-actions {
				.lector-btn,
				.download-btn {
					padding: 0.4rem 1.8rem !important;
				}
			}
		}

		.book-related-container,
		.chapter-media .media-group {
			.books-list,
			.card-list {
				flex-wrap: wrap;
				row-gap: 2rem !important;

				> *:not(.not-found)  {
					flex: 45% 0 !important;
				}
			}
		}
	}
}

@media screen and (max-width: 950px) {
	#BookDetail {
		#book-details-card {
			flex-direction: column !important;
			align-items: center;
			gap: 2rem !important;

			.image-container {
				max-width: 80%;
				min-width: 250px;
			}

			.book-actions {
				.lector-btn,
				.download-btn {
					padding: 0.4rem 3.5rem !important;
				}
			}
		}
	}
}

@media screen and (max-width: 850px) {
	#BookDetail {
		.book-related-container,
		.chapter-media .media-group {
			.books-list,
			.card-list {
				> *:not(.not-found)  {
					flex: 100% 0 !important;
				}
			}
		}
	}
}

@media screen and (max-width: 700px) {
	#BookDetail {
		#book-details-card {
			.book-actions {
				flex-direction: column;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	#BookDetail {
		padding: 4rem 5%;
	}
}

@media screen and (max-width: 400px) {
	#BookDetail {
		.book-related-container,
		.chapter-media .media-group {
			.books-list,
			.card-list {
				flex-direction: column;
				align-items: center;
				gap: 3rem !important;

				.book-card,
				.media-file-card {
					flex: 45% 0 !important;
					max-width: 100%;

					.image-container {
						flex: 30% 0 !important;

						img {
							width: 100% !important;
						}
					}

					a,
					.media-file-card-content {
						flex-direction: column;
						gap: 0 !important;

						.info-container {
							padding: 1rem !important;

							.autor {
								margin-bottom: 1rem;
							}
						}
					}
				}
			}
		}
	}
}
