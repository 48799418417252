section#perfil-card {
	position: absolute;
	z-index: 2;
	top: 100%;
	right: 0;
	background-color: white;
	border-radius: 0.35rem;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	white-space: nowrap;
	z-index: 3;

	& > * {
		padding: 1rem;
	}

	.perfil-data-container {
		display: flex;
		align-items: center;
		gap: 0.8rem;

		.perfil-icon {
		}

		.perfil-data {
			display: flex;
			flex-direction: column;
			gap: 0.1rem;

			.perfil-complete-name {
			}

			.perfil-mail {
				font-size: 0.8rem;
				opacity: 0.5;
			}
		}
	}

	.perfil-operations {
		border-block: 0.1rem solid #e6e6e6;

		.operation {
			display: inline-block;
			width: 100%;
			color: black;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.perfil-actions {
		.logout-button {
			display: flex;
			justify-content: space-between;
			cursor: pointer;

			&:hover {
				text-decoration: underline;

				svg {
					overflow: visible;

					g {
						overflow: visible;

						.arrow {
							transform: translateX(5px);
                            transition: 0.2s;
						}
					}
				}
			}
		}
	}
}
