@import "../../../../assets/styles/utils.scss";

#chat-bot-button {
	cursor: pointer;
	padding: 0.4vmax 0.9vmax;
	background-color: white;
	font-size: 1.2vmax;

	border: 0.14vmax solid #ed232a;
	color: #003876;
	border-radius: 0.35vmax;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.4vmax;
}

#floating-chat-bot-button {
	z-index: 100;
	position: fixed;
	bottom: 1rem;
	right: 1rem;

	.chat-bot-button {
		border: 0.2rem solid $dark-secondary-color;
		background-color: white;
		padding: 0.2rem;
		border-radius: 50%;
		cursor: pointer;

		.icon {
			padding: 2rem;
			background-color: $dark-secondary-color;
			border-radius: 50%;

			svg {
				@include absolute-center;
			}
		}
	}
}
