.book-card {
    height: 14rem;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #bfbfbf40;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;

    a {
        height: 100%;
        display: flex;
        color: black;
        background-color: white;
        text-decoration: none;
        gap: 1rem;
    }

    &:hover {
        transform: scale(1.05);
    }

    .image-container {
        flex: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;

        img {
            height: 100%;
            width: auto;
        }
    }

    .info-container {
        flex: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem 2rem;
        padding-left: 0;

        .text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: start;

            .title {
                font-size: 1rem;
                color: $main-color;
                font-weight: bold;
                white-space: break-spaces;
            }

            .autor {
                font-size: 0.9rem;
                text-align: end;
            }
        }
        .editorial-container {
            text-align: right;
            font-size: 0.9rem;
        }
    }

    .action-button {
        background-color: #003777;
        width: 5%;
        height: 100%;
    }
}
