.filter-selector-container {
    position: relative;

    .selector-header {
        display: flex;
        align-items: center;
        gap: 1rem;
        background-color: white;
        border: 0.1rem solid #eaeaea;
        padding: 0.6rem;
        border-radius: 0.35vmax;
        font-size: 0.8rem;
        cursor: pointer;

        .selected-option {
            min-width: 4rem;
        }

        .filter-icon {
            display: flex;
            gap: 0.7rem;
            color: #636363;
        }

        .drop-icon {
            position: absolute;
            top: 50%;
            right: 7%;
            transform: translateY(-50%);
        }
    }

    .selector-body {
        position: absolute;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        background-color: white;
        transition-duration: 0.2s;
        box-shadow: 0px 4px 4px 0px #bfbfbf40;
        z-index: 2;

        &.show {
            max-height: 1000px;
        }

        .selector-option {
            padding: 0.3rem 1rem;
            font-size: 0.8rem;
            cursor: pointer;

            &:hover {
                background-color: #eaeaea;
            }
        }
    }
}

.overlay-selector {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
